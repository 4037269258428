import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { CountryService } from 'src/app/shared/services/country.service';
import { Country } from 'src/app/shared/interfaces/country.interface';
import { Observable, of } from 'rxjs';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { startWith, map, switchMap } from 'rxjs/operators';
import { AVAILABLE_FACILITIES } from 'src/app/masjids/interfaces/masjidData.interface';
import { CountryValidators } from 'src/app/shared/validators/country.validator';

@Component({
    selector: 'app-advanced-search',
    templateUrl: './advanced-search.component.html',
    styleUrls: ['./advanced-search.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatSliderModule,
        ReactiveFormsModule,
        TranslocoModule,
        MatAutocompleteModule
    ]
})
export class AdvancedSearchComponent implements OnInit {
    searchForm: FormGroup;
    countries$: Observable<Country[]>;
    filteredCountries$: Observable<Country[]>;
    selectedCountry: Country | null = null;
    availableFacilities = AVAILABLE_FACILITIES;
    displayFn = CountryValidators.displayFn;
    
    constructor(
        private dialogRef: MatDialogRef<AdvancedSearchComponent>,
        private fb: FormBuilder,
        private countryService: CountryService
    ) {
        this.searchForm = this.fb.group({
            country: [''],
            rating: [0],
            distance: [10],
            facilities: [[]]
        });
    }

    ngOnInit() {
        console.log('Initializing countries fetch...');
        this.countries$ = this.countryService.getCountries().pipe(
            map(countries => {
                console.log('Countries fetched:', countries);
                return countries;
            })
        );
        
        this.filteredCountries$ = this.searchForm.get('country')!.valueChanges.pipe(
            startWith(''),
            map(value => {
                console.log('Form value changed:', value);
                if (typeof value === 'string') {
                    return value;
                }
                if (value) {
                    this.selectedCountry = value;
                    console.log('Selected country:', this.selectedCountry);
                    return value.name;
                }
                return '';
            }),
            switchMap(searchText => {
                console.log('Filtering countries with:', searchText);
                return this._filterCountries(searchText);
            })
        );

        // Subscribe to initial countries load
        this.countries$.subscribe({
            next: (countries) => console.log('Initial countries loaded:', countries),
            error: (error) => console.error('Error loading countries:', error)
        });
    }

    private _filterCountries(value: string): Observable<Country[]> {
        const filterValue = value.toLowerCase();
        return this.countries$.pipe(
            map(countries => {
                const filtered = countries.filter(country =>
                    country.name.toLowerCase().includes(filterValue)
                );
                console.log('Filtered countries:', filtered);
                return filtered;
            })
        );
    }

    onCountrySelected(event: MatAutocompleteSelectedEvent): void {
        CountryValidators.handleCountrySelection(
            event,
            this.searchForm.get('country')!,
            this
        );
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    resetForm(): void {
        this.searchForm.reset({
            country: '',
            rating: 0,
            distance: 10,
            facilities: []
        });
        this.selectedCountry = null;
    }

    onApply(): void {
        if (this.searchForm.valid) {
            const formValue = { ...this.searchForm.value };
            if (this.selectedCountry) {
                formValue.country = this.selectedCountry.code;
            }
            this.dialogRef.close(formValue);
        }
    }
} 