<div class="advanced-search-dialog" *transloco="let t; read: 'search'">
  <h2 mat-dialog-title>{{ t('filters.title') }}</h2>

  <mat-dialog-content>
    <form [formGroup]="searchForm" class="search-form">
      <mat-form-field appearance="outline">
        <mat-label>{{ t('filters.country') }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="country"
          [matAutocomplete]="auto"
          [placeholder]="t('filters.country')"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="onCountrySelected($event)"
        >
          <mat-option
            *ngFor="let country of filteredCountries$ | async"
            [value]="country"
          >
            <div class="country-option">
              <img
                class="country-flag"
                [src]="country.flag"
                [alt]="country.name"
              />
              <span>{{ country.name }}</span>
            </div>
          </mat-option>
        </mat-autocomplete>
        <mat-error
          *ngIf="searchForm.controls['country'].errors?.['countryValidator']?.error"
        >
          {{ searchForm.controls['country'].errors?.['countryValidator']?.error | transloco }}
        </mat-error>
        <mat-error
          *ngIf="searchForm.controls['country'].hasError('required') && !searchForm.controls['country'].errors?.['countryValidator']"
        >
          {{ 'validations.country.countryRequired' | transloco }}
        </mat-error>
      </mat-form-field>

      <div class="slider-field">
        <label>{{ t('filters.rating') }}</label>
        <mat-slider min="0" max="5" step="0.5" discrete>
          <input matSliderThumb formControlName="rating" />
        </mat-slider>
        <span class="slider-value"
          >{{ searchForm.get('rating')?.value || 0 }} ★</span
        >
      </div>

      <div class="slider-field">
        <label>{{ t('filters.distance') }}</label>
        <mat-slider min="0" max="50" step="1" discrete>
          <input matSliderThumb formControlName="distance" />
        </mat-slider>
        <span class="slider-value"
          >{{ searchForm.get('distance')?.value || 0 }} km</span
        >
      </div>

      <mat-form-field appearance="outline">
        <mat-label>{{ t('filters.facilities') }}</mat-label>
        <mat-select formControlName="facilities" multiple>
          <mat-option
            *ngFor="let facility of availableFacilities"
            [value]="facility"
          >
            {{ t('facilities.' + facility) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="mat-dialog-actions">
    <button mat-button (click)="onCancel()">
      {{ t('filters.cancel') }}
    </button>
    <button mat-raised-button color="primary" (click)="onApply()">
      {{ t('filters.apply') }}
    </button>
  </mat-dialog-actions>
</div>
